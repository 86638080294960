.fade-title-enter {
  opacity: 0;
  transform: translateY(50%);
}
.fade-title-exit {
  opacity: 1;
  transform: translateY(0%);
}
.fade-title-enter-active {
  opacity: 1;
  transform: translateY(0%);
}
.fade-title-exit-active {
  opacity: 0;
  transform: translateY(50%);
}
.fade-title-enter-active,
.fade-title-exit-active {
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-char-enter {
  opacity: 0;
  transform: translateY(-50%);
}
.fade-char-exit {
  opacity: 1;
  transform: translateY(0%);
}
.fade-char-enter-active {
  opacity: 1;
  transform: translateY(0%);
}
.fade-char-exit-active {
  opacity: 0;
  transform: translateY(-50%);
}
.fade-char-enter-active,
.fade-char-exit-active {
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-sub-enter {
  opacity: 0;
  /* transform: translateY(50%); */
}
.fade-sub-exit {
  opacity: 1;
  transform: translateY(0%);
}
.fade-sub-enter-active {
  opacity: 1;
  transform: translateY(0%);
}
.fade-sub-exit-active {
  opacity: 0;
  /* transform: translateY(50%); */
}
.fade-sub-enter-active,
.fade-sub-exit-active {
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
