.play-button-enter {
  opacity: 0;
  transform: rotate(-5deg);
}
.play-button-exit {
  opacity: 1;
  transform: rotate(0);
}
.play-button-enter-active {
  opacity: 1;
  transform: rotate(0);
}
.play-button-exit-active {
  opacity: 0;
  transform: rotate(5deg);
}
.play-button-enter-active,
.play-button-exit-active {
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: 50% 50%;
}

.play-button-txt-enter {
  opacity: 0;
  transform: translateX(-30%);
}
.play-button-txt-exit {
  opacity: 1;
  transform: rotate(0);
}
.play-button-txt-enter-active {
  opacity: 1;
  transform: rotate(0);
}
.play-button-txt-exit-active {
  opacity: 0;
  transform: translateX(-30%);
}
.play-button-txt-enter-active,
.play-button-txt-exit-active {
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
