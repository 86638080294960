@keyframes apperLetter1 {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  35% {
    opacity: 0.75;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes apperLetter2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  65% {
    opacity: 0.75;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes apperLetter3 {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.75;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes apperLetter4 {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  55% {
    opacity: 0.75;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes apperLetter5 {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  85% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.logo-letter-1 {
  animation: apperLetter1 5s cubic-bezier(0.4, 0, 0.2, 1) 1.5s infinite;
}
.logo-letter-2 {
  animation: apperLetter2 5s cubic-bezier(0.4, 0, 0.2, 1) 1.5s infinite;
}
.logo-letter-3 {
  animation: apperLetter3 5s cubic-bezier(0.4, 0, 0.2, 1) 1.5s infinite;
}
.logo-letter-4 {
  animation: apperLetter4 5s cubic-bezier(0.4, 0, 0.2, 1) 1.5s infinite;
}
.logo-letter-5 {
  animation: apperLetter5 5s cubic-bezier(0.4, 0, 0.2, 1) 1.5s infinite;
}

@keyframes apperLetter1fast {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes apperLetter2fast {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes apperLetter3fast {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes apperLetter4fast {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes apperLetter5fast {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  85% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.logo-letter-1-fast {
  animation: apperLetter1fast 1.25s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
.logo-letter-2-fast {
  animation: apperLetter2fast 1.25s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
.logo-letter-3-fast {
  animation: apperLetter3fast 1.25s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
.logo-letter-4-fast {
  animation: apperLetter4fast 1.25s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
.logo-letter-5-fast {
  animation: apperLetter5fast 1.25s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
