body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #38b3f6;
  transform-origin: bottom;
  z-index: 1497;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #38b3f6;
  transform-origin: top;
  z-index: 1497;
}

.slide {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1497;
  background: #38b3f6;
}

.loader-transition-in {
  position: fixed;
  top: 0;
  left: 100vw;
  width: 100vw;
  height: 100vh;
  z-index: 1497;
  background: #0066ff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
