.to-top-button {
  cursor: pointer;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
  gap: 2px;
  color: #fff;
  background-color: #0066ff;
}
