.header-enter {
  /* opacity: 0; */
  transform: translateY(-100%);
}
.header-exit {
  opacity: 1;
  transform: translateY(0%);
}
.header-enter-active {
  opacity: 1;
  transform: translateY(0%);
}
.header-exit-active {
  /* opacity: 0; */
  transform: translateY(-100%);
}
.header-enter-active,
.header-exit-active {
  transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
}
