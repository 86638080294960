.fade-enter {
  opacity: 0;
  transform: translateX(20%);
}
.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active {
  opacity: 0;
  transform: translateX(-20%);
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
}
