.fade-description-enter {
  opacity: 0;
  transform: translateY(-40%);
}
.fade-description-exit {
  opacity: 1;
  transform: translateY(0%);
}
.fade-description-enter-active {
  opacity: 1;
  transform: translateY(0%);
}
.fade-description-exit-active {
  opacity: 0;
  transform: translateY(-40%);
}
.fade-description-enter-active,
.fade-description-exit-active {
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
